import React from 'react'
import ReactDOM from 'react-dom/client'
import './scss/styles.scss'; //Adjust path!
//import * as bootstrap from 'bootstrap';
import App from './App.tsx'
//import './index.css'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
