/**
 * Rendering portions of the site
 */
import { useSignals } from "@preact/signals-react/runtime";
import {useAxiosGet, useAxiosPost,} from 'pk-ts-fe-lib';
import Select from 'react-select'
import { styled } from 'styled-components';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import { Component, useState, useEffect, } from 'react';
import { isEmpty, firstToUpper, typeOf, typeOfEach, GenObj, insertBetween, JSON5Stringify, pkToDate, } from 'pk-ts-common-lib';
import apiLib, { inCreateBatch, useFetchBatches,
  useFetchBatch, site_title,
  //useAxiosFetchBatches, 
  signalBatch, sysRefs, batchId, useGetUsers, useNewGetUser, useGetUser, userId,  } from '../apiUtils.js';
import { 
  //BatchForm, BatchFormValuesI, 
  BatchFormHF } from './BatchForm.js';
import {BatchDetails,

} from './batchComponents.js';
import { formatISO, isValid, add, } from "date-fns";
import { format, } from "date-fns/format";

export const commonBlock = {
  height: "2em",
  border: "solid #aaa 1px",
  padding: ".3em",
};
export const styles = {
  labelBlock: {
    ...commonBlock,
    background: "#fef",
    color: "#284",
    'text-align': 'center',
    border: "solid #aaa 1px",
  },
  valueBlock: {
    ...commonBlock,
    background: "#eff",
    color: "#808",
    border: "solid #faa 1px",
  },

  verticalPair: {
    display: "flex",
    'flex-direction': "column",
    border: "solid #888 1px",
    margin: ".25em"
  },
  horizontalPair: {
    display: "flex",
    border: "solid #888 1px",
    margin: ".25em"
  },
  batchContainer: {
    display: "flex",
    'flex-direction': "column",
    border: "solid #fa8 1px",
    margin: ".25em",
    padding: ".5em",
  },
  headLabel: {
    background: "#aaf",
    'text-align': "center",
    "font-weight": "bold",
    //  "font-size": "larger",
    padding: ".5em",
    color: "#400",
  }
};

export function Waiting() {
    return (<h1 style={{color:"red"}}>Waiting...</h1>)
}
/** Takes array of users & returns just id & name */
function mkSelectUserOpts(users) {
  let options = [];
  for (let user of users.users) {
    options.push({ value: user.id, label: user.name })
  }
  return options;
}
export function mkSelectBatchOptions(batches) {
  let options = [];
  for (let batch of batches) {
    options.push({ value: batch.id, label: batch.batchId })
  }
  return options;
}

export function SelectBatch({ id, ...props }) {
  useSignals();
  let lBatchId = null;
  let { user, isError, isLoading } = useGetUser(id);
  if (isLoading) return (<div>Is Loading</div>);
  user = user.user;
  let selectedBatch: GenObj = {};
  if (isEmpty(user)) {
    return (<div>No User</div>);
  }
  let batches = user.batches;
  if (isEmpty(batches)) {
    return (<div>No Batches</div>);
  }
  let selectedOption: any = null;
  let options = mkSelectBatchOptions(batches);

  function onBatchSelect(arg) {
    id = arg.value;
    lBatchId = arg.value;
    selectedOption = arg;
    batchId.value = arg.value;
    //console.log(`in onBatchSelect; selected:`, { id, lBatchId, });
  }

  return (
    <>
      <VerticalPair className="w10 tac" style={{ width: "10em" }}>
        <ValLabel>Select a Batch</ValLabel>
        <Select className="select-user mh5"
          defaultValue={selectedOption}
          onChange={onBatchSelect}
          options={options}
        />
      </VerticalPair>
    </>
  );
}


export const HeadLabel: any = styled('div')(styles.headLabel);
export const HorizontalPair: any = styled('div')(styles.horizontalPair);
export const VerticalPair: any = styled('div')(styles.verticalPair);
export const SelectLabel: any = styled('div')(styles.labelBlock);
export const DataVal: any = styled('div')(styles.valueBlock);
export const ValLabel: any = styled('div')({ display: "inline-block", 'text-align': "center", color: "white" });


export function BatchContainer({id, ...props}) {
  useSignals();
  let batch = useFetchBatch({where:{id}});
  if (!batch) {
    return (<Waiting />);
  }
  if (isEmpty(batch)) {
    return (<div className="GenLabel">No Batch Selected</div>)
  }
  let labelsVals = formatBatchData(batch);
  return (
    <Col className="maxw25" style={{ margin: ".5em", border: "solid #888 1px", padding: ".2em" }}>
      <HeadLabel>Batch: <span style={{ color: "blue" }}>{batch.batchId}</span></HeadLabel>
      {
        Object.entries(labelsVals).map(([key, val]) => (
          <VerticalPair key={key}>
            <ValLabel>{val.label}</ValLabel>
            <DataVal>{val.value}</DataVal>
          </VerticalPair>
        ))
      }
    </Col>
  )
}

/**
 * Iterates through array of batches, returns batch w. batch.id = aBatchId
 * @param batches 
 * @param aBatchId 
 */

//export function BatchesContainer({ batches, ...props }) {
export function xBatchesContainer({ ...props }) {
  useSignals();
  userId.value = `${userId.value}`;
  let bidv = batchId.value;
  
  // Try my new custom
  let data = useFetchBatches();


  //console.log(`in BatchesContainer - data:`, { data });
  let batches = data.batches;

  //console.log('in batchesContainer', { batches, props, bidv });
  /*
  if (isEmpty(batches)) {
    return (<h2>Whoops - no batches</h2>);
  }
  */
 /*
  if (bidv) {
    console.log("Got a batchId - look for batch in batches..", { bidv });
    let batch = getBatchFromBatchArrById(batches, bidv);
    if (!isEmpty(batch)) {
      return (<BatchContainer batch={batch} />)
    } else {
      console.error(`FAILED Looking for batch from bidv: [${bidv}]`);
    }
  }
  */
  return (
    <>
      <HeadLabel>User Batch Reports</HeadLabel>
      <Row className="batches-row">
        { /*
        {batches.map((val, key) => (<BatchContainer key={key} batch={val} />))}
  */ }
      </Row>
    </>
  );
}

export const formatters: GenObj = {
  date: function (val, key?: string) {
    let jsdate = pkToDate(val);
    //@ts-ignore
    let formatted = format(jsdate, "d MMM yyy");
    //console.log(`In date formatter for [${key}], after formatting,  formatted:: [${formatted}]`);
    return formatted;
  },
  amt_w_units:function(val, ) {


  },
  farm: function (farm) {
    return farm.name;
  },
  quality: function (val, key?: string) {
    let qRef = sysRefs.quality[val];
    let name = qRef.name;
    let color = qRef.color;
    //return (<span style={{color, fontWeight:"bold"}}>{name}</span>);
    return (<div style={{ color, fontWeight: "bold", background: "white" }}>{name}</div>);
  },
  name: function (val, key, category) {
    let name = sysRefs[category][val].name;
    return name;
  }
}



/**
 * Takes a raw batch object & returns labels & formatted values
 * @param batchData T
 * @return array of objects [{label, value}]
 */
export function formatBatchData(batchData) {
  let labelValFormats = {
    farm: { label: "Farm", format: "farm" },
    harvestDate: { label: "Harvest Date", format: "date" },
    bestBy: { label: "Best By", format: "date" },
    product: { label: "Product", format: "name", category: "product" },
    //quantity: { label: "Quantity", format: "amt_w_units" },
    quantity: {},
    packageSize: { label: "Package Size", },
    total: {},
    packageUnits: { label: "Package Unit", format: "name", category: "units" },
    quality: { label: "Quality", format: "quality" },
    batchId: { label: "Batch ID", },
  }


  let keys = Object.keys(labelValFormats);
  let labelVals: GenObj = {};
  for (let key of keys) {
    let value = batchData[key];
    let lvRow = labelValFormats[key];
    let label = lvRow.label || firstToUpper(key);
    let category = lvRow.category;
    let formatName = lvRow.format;
    //console.log(typeOfEach({formatter,lvRow},true));
    if (formatName) {
      if (formatName in formatters) {
        let formatter = formatters[formatName];
        value = formatter(value, key, category);
      } else {
        console.error(`formatName [{$formatName}] not found in formatters`);
      }
    }
    labelVals[key] = { label, value };
  }
  return labelVals;
}



export function ChooseBatchRow({ id, ...props }) {
  function setShowCreate() { console.log(`Shouldn't see this anymore`); }
  useSignals();
  let { user, isError, isLoading } = useGetUser(id);
  if (isLoading) {
    return (<div>Loading...</div>)
  }
  let handleClickAllBatches = (...args) => {
    batchId.value = null;
  }
  //console.log(`in ChooseBatchRow; user:`, { user, });
  if (inCreateBatch.value) {
    return (
      <div className="flex-start-center m3 p3 bdg ">
        <CreateBatch  setShowCreate={setShowCreate} user={user.user} />
      </div>
    )
  } else {
    //userId.value = `${userId.value}`;
    return (
      <div className="flex-start-center m3 p3 bdg ">
        <SelectBatch id={userId.value} />
        <Button className="mh4" onClick={handleClickAllBatches} >
          Show All Batches
        </Button>
        <CreateBatch setShowCreate={setShowCreate} user={user.user} />
      </div>
    )
  }
}

export function formatUserData(userData, defaultClassName = "col") {
  let labelVals: GenObj = {
    email: { label: "Email", value: userData.email, },
    name: { label: "Name", value: userData.name, },
    farm: { label: "Farm", value: userData.farm.name, },
    //batchCnt: { label: "# Batches", value: userData.batches.length, },
  };
  for (let key in labelVals) {
    let className = labelVals[key].className;
    labelVals[key].className = classNames(className, defaultClassName);
  }
  return labelVals;
}

let dummyNoShowForm = (<></>);


export function CreateBatch({ user, setShowCreate, ...props }) {
  useSignals();
  //let { user, isError, isLoading } = useGetUser(id);
  let [showForm, setShowForm] = useState(false);
  let handleClick = (...args) => {
    setShowCreate(true);
  }
  return (
    <>
      <Button className="mh4" onClick={handleClick} >
        {showForm ? (<span>Close Create</span>) : (<span>New Batch</span>)}
      </Button>
    </>
  );
}
export function UserCols({ id, ...props }) {
  useSignals();
  /*
  let { user, isError, isLoading } = useGetUser(id);
  if (isLoading) {
    return (<div>Loading...</div>)
  }
  */
  let user = useNewGetUser();
  if (!user) {
    return (<Waiting />);
  }
  //console.log({ user });
  let userFields = formatUserData(user);
  return (
    <>
      {
        Object.entries(userFields).map(([key, val]) => (
          <VerticalPair key={key} className={val.className || "maxw20"}>
            <ValLabel className="tac">{val.label}</ValLabel>
            <DataVal>{val.value}</DataVal>
          </VerticalPair>
        ))}
    </>
  );
}


export function SelectUser({setUid=null,...props}) {
  useSignals();
  //console.log(`in SelectUser, sysRefs:`, { sysRefs });
  const [selectedOption, setSelectedOption] = useState(null);
  let { users, isError, isLoading } = useGetUsers();
  function onUserSelect(selectedUser, ...args) {
    //console.log(`userSelected - args:`, { selectedUser, args });
    let selected = selectedUser;
    let id = selected.value;
    userId.value = id;
    if (setUid) {
      setUid(id);
    }
    setSelectedOption(selected);
  }
  if (isLoading) {
    return (<h1>Loading...</h1>);
  }
  let options = mkSelectUserOpts(users);
  return (
    <>
      <VerticalPair className={props.className}>
        <ValLabel>Select a User</ValLabel>
        <Select className="select-user mh5"
          defaultValue={selectedOption}
          onChange={onUserSelect}
          options={options}
        />
      </VerticalPair>
    </>
  );
}


      /*
      { userId.value ? (
      <h1>User selected</h1>
      )
      : (
      <h1>User NOT selected</h1>
      )}
      <h1 className="maxw10 minw5 m2 p2 toc" style={{color:"red", }}>{site_title}</h1>
      */
export function PPSelectUserRow({setUid=null, ...props}) {
  return (
    <div className="flex-start-start pp-sel-user-row">
      <SelectUser className="minw10" setUid={setUid}/>
      <UserCols id={userId.value} />
    </div>
  );
}


