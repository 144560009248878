/**
 * api support for PP FE
 */
export const site_title="Lemon Link";
import useSWR from 'swr';
import { useAxiosGet, useAxiosPost } from 'pk-ts-fe-lib';
import { useState, useEffect } from 'react';
import { signal, computed, effect } from "@preact/signals-react";
import axios from 'axios';
import { GenObj } from 'pk-ts-common-lib';
import _ from 'lodash';
axios.defaults.baseURL = "/api";
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';

export {axios as paxios};

export const userId = signal(null);
export const sysUser = signal(null);
export const inCreateBatch = signal(false);
//effect(() => console.log(`The signal userId has changed:`, { newVal: userId.value }));

export const batchId = signal(null);
export const signalBatch = signal({});

/**
 * Returns the name/label of the category value in sysRefs - like 
 * getNameFromCat('quality','good') returns 'Good'
 */
export function nameFromCatKey(catName: string, key: string, propname = 'name'): string {
  return propValFromCatKey(catName, key);
}

export function propValFromCatKey(catName: string, key: string, propname = 'name'): string {
  return sysRefs[catName][key][propname];
}

export function useGetRefs() {
  function refsFetcher() {
    return axios.get('/refs').then(res => res.data);
  }
  const { data, error, isLoading } = useSWR('no-need', refsFetcher);
  return { refs: data, isLoading, isError: error, };
}

//GETS

// TODO! This doesn't look right....
/*
export function useGetFarms(id?: string | number) {
  return useSWR('getting farms', (id) => axios.get(`/farm/${id}`).then(res => res.data));
}
*/

/** Fix later - for now, just all farms */
export function useFetchFarms(conditions:GenObj = {}) { 
  let [farms, setFarms] = useState(null);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(true);
  let baseWhere = { where: { "user.id": userId.value } };
  //_.merge(batchConditions, baseWhere);
  useEffect(() => {
    if (loading) {
    axios.post('/fetchfarms', conditions)


        .then((res) => {
          let lfarms = res.data;
          console.log(`apuUtils useFetchFarms:`, {   lfarms });
          setFarms(lfarms);
          setLoading(false);

        })


      //.then(res => res.data)







      .catch((error) => {
        let errorJSON = error.toJSON();
        console.error(`Error in useFetchFarms:`, { conditions, error, errorJSON, });
        setError(error.toJSON());
        setFarms(null);
        setLoading(false);
      });
}},[loading, farms]);

  return farms;
};

  //axios.post(


export function useGetUsers() {
  function usersFetcher(arg: any) {
    return axios.get('/users').then(res => res.data);
  }
  const { data, error, isLoading } = useSWR(`some random string`, usersFetcher);
  return { users: data, isLoading, isError: error };
}


export function useGetUser(id) {
  function userFullFetcher(id) {
    return axios.get(`/user/${id}`).then(res => res.data);
  }
  const { data, error, isLoading } = useSWR(id, userFullFetcher);
  return { user: data, isLoading, isError: error };
}

// Should return all instances of type entityName
export function useGetInstances(entityName: string) {
  function instancesFetcher(entName) {
    return axios.get(`/instances/${entName}`).then(res => res.data);
  }
  const { data, error, isLoading } = useSWR(entityName, instancesFetcher);
  return { [entityName]: data, isLoading, isError: error };
}

export function useGetBatch(batchId: string) {
  function instanceFetcher(batchId) {
    //console.log(`in instanceFetcher for batch; batchId: ${batchId}`);
    return axios.get(`/batch/${batchId}`).then(res => res.data);
  }
  const { data, error, isLoading } = useSWR(batchId, instanceFetcher);
  return { batch: data, isLoading, isError: error };
}

//POSTS


export async function axiosPostBatch(batchData: GenObj) {
  let res = await axios.post('/batch', batchData);
  let data = res.data;
  //console.log(`After await axiosPostBatch:`, { res, data });
  return data;
}

/*
export function axiosFetchBatches(fakeUrl, batchConditions: GenObj = {}) {
  let baseWhere = { where: { "user.id": userId.value } };
  _.merge(batchConditions, baseWhere);
  //batchData.where = { "user.id": userId.value };
  //  console.log(`in axiosFetchBatches`, { batchData });

  let res = axios.post('/fetchBatches', batchConditions).then(res => res.data);
  return res;
  //let data = res.data;
  //console.log(`After await axiosFetchBatches:`,{res, data});
  //return data;
}

export function useAxiosFetchBatches(batchConditions: GenObj = {}) {
  const { data, error, isLoading } = useSWR(['pointless-url', batchConditions], url => axiosFetchBatches(url, batchConditions));
  if (!isLoading) {
    //console.log(`in useAxiosFetchBatches, data:`, { data });
  }
  return { data, isLoading, error };
}
*/


// New - w/o SWR; my simplified versions

export function useFetchBatch(id, batchConditions: GenObj = {}) {
  let baseWhere = { where: { id, "user.id": userId.value } };
  //"user.id": userId.value } };
  _.merge(batchConditions, baseWhere);
  //let [batches, setBatches] = useState(null);
  let [batch, setBatch] = useState(null);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(true);
  useEffect(() => {

    if (userId.value && id) {
      axios.post('/fetchbatches', batchConditions)
        .then((res) => {
          let data = res.data;
          let batches = data.batches;
          console.log(`apuUtils useFetchBatch:`, { id, batches });
          setBatch(batches[0]);
          setLoading(false);

        })
        .catch((error) => {
          let errorJSON = error.toJSON();
          console.error(`Error in useFetchBatches:`, { batchConditions, error, errorJSON, });
          setError(error.toJSON());
          setBatch(null);
          setLoading(false);
        });
    }
  });
  return batch;
}








/*
let batches = useFetchBatches(batchConditions);
if (!batches) {
  return (<Waiting />);
}
if (Array.isArray(batches)) {
  let batchesCnt = batches.length;
  if(batchesCnt === 1) {
    return batches[0];
  } else {
    console.error(`Bad Batch Cnt in useFetchBath:`, {batchConditions, batches, batchesCnt});
  }
}
return batches;

}
*/
export function useFetchBatches(batchConditions: GenObj = {}) {
  let [batches, setBatches] = useState(null);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(true);
  let baseWhere = { where: { "user.id": userId.value } };
  _.merge(batchConditions, baseWhere);
  console.log(`in useFetchBatches - merged conds:`,{batchConditions});
  useEffect(() => {
    axios.post('/fetchbatches', batchConditions)
      .then(res => res.data)
      .catch((error) => {
        let errorJSON = error.toJSON();
        console.error(`Error in useFetchBatches:`, { batchConditions, error, errorJSON, });
        setError(error.toJSON());
        setBatches(null);
        setLoading(false);
      });
  });

  return batches;
}


export function useNewGetUser() {
  let [user, setUser] = useState(null);
  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userId.value) {
      let uid = userId.value;
      //console.log(`Entering useEffect of useNewGetUser; uid: [${uid}]`);
      let url = `/user/${userId.value}`;

      axios.get(url)
        .then((res => {
          let data = res.data;
          let guser = data.user;
          userId.value = guser.id;
          setUser(guser);
          setLoading(false);
        }))
        .catch((error) => {
          let errorJSON = error.toJSON();
          console.error(`Error in useNewGet User:`, { url, error, errorJSON, });
          setError(error.toJSON());
          setUser(null);
          setLoading(false);
        });
    }
  }, [userId.value]);
  return user;
}


export let sysRefs: GenObj = {};

export default {
  //userFullFetcher, 
  useGetUser, signalBatch, batchId, userId, sysRefs, useGetUsers,
  useFetchBatches,
  //usersFullFetcher, 
  useGetRefs,
  useFetchFarms,
  //refsFetcher, 
  //usePostBatch,
  // useGetBatch, 
  //useGetFarms, 
  //useAxiosFetchBatches,
  //axiosFetchBatches,
};










