/**
 * Componets for showing/editing/etc batches in PP
 */
import { useSignals } from "@preact/signals-react/runtime";
import {
  useAxiosGet, useAxiosPost,

} from 'pk-ts-fe-lib';
import _ from 'lodash';
import Select from 'react-select';
import { styled } from 'styled-components';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import { Component, isValidElement, useState, useEffect, Fragment, } from 'react';
import { isEmpty, firstToUpper, typeOf, typeOfEach, GenObj, insertBetween, JSON5Stringify, pkToDate, } from 'pk-ts-common-lib';
import apiLib, { nameFromCatKey, propValFromCatKey, inCreateBatch, useFetchBatch,
  // useAxiosFetchBatches, 
signalBatch, sysRefs, batchId, useGetUsers, useGetUser, userId, } from '../apiUtils.js';
import { 
  //BatchForm, BatchFormValuesI, 
  BatchFormHF } from './BatchForm.js';
import { formatISO, isValid, add, } from "date-fns";
import { format, } from "date-fns/format";
import {
  SelectBatch, SelectUser, UserCols, ChooseBatchRow, 
  //BatchesContainer,
  Waiting,
  CreateBatch,

} from '../components/displayComponents.js';

/**
 * Adds class names to comp props w.  existing className
 * 
 * Lazy way to avoid too much typing...
 */
export function applyClassNames(props, ...cnames) {
  let className = classNames(props.className, ...cnames);
  return {...props, className};
}

let tblRowCNames =  "fr-g-g bc-222 fullw";

export function TblHeadRow(props) {
  return (<div {...applyClassNames(props,tblRowCNames)}/>);
}
export function TblValRow(props) {
  return (<div {...applyClassNames(props,tblRowCNames, "bg-fff")}/>);
}


export function RowCC(props) {
  return (<div {...applyClassNames(props,  'fr-c-c m3 p3 bc-222')} />)
}
export function RowSC(props) {
  return (<div {...applyClassNames(props,'fr-s-c m3 p3 bc-222')}/>);
}
export function RowGC(props) {
  let className = classNames(props.className, 'fr-g-c m3 p3 bc-222');
  return (<div {...props} className={className} />);
}
export function RowCG(props) {
  let className = classNames(props.className, 'fr-g-c m3 p3 bc-222');
  return (<div {...props} className={className} />);
}
export function RowGG(props) {
  let className = classNames(props.className, 'fr-g-g m3 p3 bc-222');
  return (<div {...props} className={className} />);
}
export function RowSS(props) {
  let className = classNames(props.className, 'fr-s-s m3 p3 bc-222');
  return (<div {...props} className={className} />);
}
export function RowSG(props) {
  let className = classNames(props.className, 'fr-s-g m3 p3 bc-222');
  return (<div {...props} className={className} />);
}
export function ColSG(props) {
  let className = classNames(props.className, 'fc-s-g m2 p2 bc-888');
  return (<div {...props} className={className} />);
}
export function ColSS(props) {
  let className = classNames(props.className, 'fc-s-s m2 p2 bc-888');
  return (<div {...props} className={className} />);
}
export function ColSC(props) {
  let className = classNames(props.className, 'fc-s-c m2 p2 bc-888');
  return (<div {...props} className={className} />);
}

export function ColCC(props) {
  let className = classNames(props.className, 'fc-c-c m2 p2 bc-888');
  return (<div {...props} className={className} />);
}

let tblColCname = "fg1 fs1 inner-border fb0";
export function ColTbl(props) { //Doesn't have to be flex
  return (<div {...applyClassNames(props, tblColCname)}/>);
}

export function ColItem(props) {
  //let className = classNames(props.className, 'm2 p2 bc-888');
  let className = classNames(props.className, 'fg1 inner-border');
  return (<div {...props} className={className} />);
}
/**
 * Iterates through an object, uses obj keys as keys, & returns/renders vals
 */

export function renderObj({ obj, ...props }) {
  let render = props.render || (val => val);
  return (
    <>
      {
        Object.entries(obj).map(([key, val]) => (
          < Fragment key={key}>
            {/* @ts-ignore */}
            {render(val, key)}
          </Fragment>
        ))
      }
    </>
  );
}

export function BatchesTopRow({ batches, user, ...props }) {
  let [showCreate, setShowCreate] = useState(false);
  let numBatches = batches.length;
  if (showCreate) {
    return (<BatchFormHF setShowCreate={setShowCreate} />);
  }
  return (<RowSG className="bc-fee m2 p2">
    <ColSG><div className="bg-000 c-bbb">Num Batches:</div> <div className="bg-fff c-000">{numBatches}</div></ColSG>
    <ColSG><SelectBatch id={user.id} ShowComponent={BatchDetails}/></ColSG>
    <ColCC><CreateBatch user={user} setShowCreate={setShowCreate} /></ColCC>
  </RowSG>
  );
}


export interface IRowDef {
  header: string,
  headerStyle?: any, // how to differentiate header from content
  comp?: any, // The base component to render both header and content col
  extra?: any, // whatever else... 

}

export interface ITableLike {
  [key: string | number]: IRowDef;
};

/**
 * Add default properties to an object without 
 * @param obj:object - the object to fill
 * @param defaults:object - defaults if missing
 * @return NEW object, from orig w. defaults
 */
export function fillDefaults(obj: any, defaults: any) {
  return _.merge({}, defaults, obj);
};

/**
 *  Create a pseudo-table - flex based, w. a table header & values in rowData
 * @param tDef - object defining the headers, fields, components, etc.
 * @param rowData : array - array of data objects, keys should correspond to tDefs
 * @param rowComp: optional - the "Row" component, else default
 */
let batchSummaryCats = { 
  batch: { header: "Batch", }, 
  product: "Product", 
  harvestDate: "Harvest", 
  bestBy: "Best By", 
  pkgSum: "Units/Sz", 
  origQ: "Orig",
  soldQ: "Sold",
  remainQ: "Left",
};

/**
 * Formats batch data for summary. Keys 
 */
let batchSummDefs = {
  comp: ColTbl,
  className: 'tac ',
  headerStyle: { fontWeight: 'bold', color:'blue', background:'#eef' },
};

export function normalizeTblDef(obj: GenObj, defaults?: GenObj) {
  if (isEmpty(defaults)) {
    defaults = batchSummDefs;
  }
  let normed = {};
  for (let key in obj) {
    let val = obj[key];
    if (typeof val === 'string') {
      val = { header: val };
    }
    if (!val.headerStyle) {
    }
    normed[key] = { ...defaults, ...val };
  }
  return normed;
}

/**
 * Returns renderable header data for output in component 
 */
export function dtFmt(date):string {
  let jsdate = pkToDate(date);
  //@ts-ignore
  let formatted = format(jsdate, "d MMM yy");
  return formatted;
}
export function mkTbHeaderRow(rowDefObj, defaults?: GenObj) {
  let ret: GenObj = {};
  let normDef = normalizeTblDef(rowDefObj, defaults);
  for (let key in normDef) {
    let parms = normDef[key];
    //console.log(`In mk header row; parms:`,{parms});
    ret[key] = (<parms.comp key={key} className={parms.className} style={parms.headerStyle}>{parms.header}</parms.comp>);
  }
  return Object.values(ret);
}

export const batchFrmts = {
  batch: (bdata) => bdata.batchId,
  product: (bdata) => nameFromCatKey('product',bdata.product),
  harvestDate: (bdata) => dtFmt(bdata.harvestDate),
  bestBy: (bdata) => dtFmt(bdata.bestBy),
  pkgSum: (bdata) => `${bdata.quantity} / ${bdata.packageSize} ${sysRefs.units[bdata.packageUnits].name}`,
  //total: (bdata) => `${bdata.quantity * bdata.packageSize} ${sysRefs.units[bdata.packageUnits].name}`,
  origQ: (bdata) => `${bdata.origQ} ${sysRefs.units[bdata.packageUnits].name}`,
  soldQ: (bdata) => `${bdata.soldQ} ${sysRefs.units[bdata.packageUnits].name}`,
  remainQ:(bdata) => `${bdata.remainQ} ${sysRefs.units[bdata.packageUnits].name}`, 
};

/**
 * Returns renderable batch data
 */
export function fncMkBatchRow(batchData, rowDefObj, defaults?: GenObj) {
  let normDef = normalizeTblDef(rowDefObj, defaults);
  //Set bg by quality
  let quality = batchData.quality;
  let color =  sysRefs.quality[quality].color;
  let style = { backgroundColor: sysRefs.quality[batchData.quality].color };

  let ret: GenObj = {};
  for (let key in normDef) {
    let parms = normDef[key];
    let bVal = batchFrmts[key](batchData);
    ret[key] = (<parms.comp key={key} className={parms.className} style={{ ...parms.style, ...style }}>{bVal}</parms.comp>);
  }
  return Object.values(ret);
}



export function BatchRowsSum(batchesdata, RowComp = TblValRow) {
  return batchesdata.map((bdata, idx) =>
    //@ts-ignore
    (<RowComp key={idx}>{Object.values(fncMkBatchRow(bdata,batchSummaryCats ))}</RowComp>)
  );
}

export function BatchesSummary({ batches, ...props }) {
  //console.log(`In batch sum, batches:`, { batches });
  return (
    <ColSS className="bg-000 c-fff bc-f44 fw-1">
      <TblHeadRow>
        {Object.values(mkTbHeaderRow(batchSummaryCats))}
      </TblHeadRow>
      {BatchRowsSum(batches)}
    </ColSS>
  );
}

export function BatchDetails({id,...props}) {
  //let batchConditions = {relations:{sales:true}};
  let batchConditions = {};
  //console.log(`in BatchDetails, id:`,{id});
  let batch = useFetchBatch(id, batchConditions);
  if (!batch) {
    return (<Waiting />);
  }
  console.log(`Batch Details:`,{batch});
  return (
    <h1>Got BatchDetails</h1>
  );
}


export default { BatchDetails, RowSS, BatchesTopRow, BatchesSummary,  };

















