//import { FormData, CreateForm,} from '../components/BatchForm';
import { useSignals } from "@preact/signals-react/runtime";
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { Component, useState, useEffect, } from 'react';
import { isEmpty, firstToUpper, typeOf, typeOfEach, GenObj, insertBetween, JSON5Stringify, pkToDate, } from 'pk-ts-common-lib';
import apiLib, { inCreateBatch, 
   // useAxiosFetchBatches, 
   signalBatch, sysRefs, batchId, useGetUsers, useGetUser, userId, useNewGetUser, } from '../apiUtils.js';
import {
   SelectUser, PPSelectUserRow, UserCols, ChooseBatchRow, 
   //BatchesContainer,

} from '../components/displayComponents.js';
import {
   BatchesTopRow, BatchesSummary, BatchDetails,
} from '../components/batchComponents.js';
//export const batchFormData:FormData = { } 
export function Batch() {
   useSignals();
  const [uid, setUid] = useState(null);
   let user = useNewGetUser();
   if (!user) {
      return (
         <>
        <PPSelectUserRow setUid={setUid} />
      <div> Waiting for user select</div>
      </>
   );
   }
   let batches = user.batches;
   console.log(`in Batch page for userID [${user.id}] with batches:`,{batches});

   return (
      <>
        <PPSelectUserRow setUid={setUid} />
        { /*
         <div className="fr-s-s">
            <UserCols id={user.id} />
         </div>
   */ }
         <Col>
         <BatchesTopRow user={user} batches={user.batches} />
         <Row>
            <h1>Batches Summary</h1>
         </Row> 
         <BatchesSummary batches={user.batches} />
         </Col>
      </>
   );
}