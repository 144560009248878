import Container from 'react-bootstrap/Container';
import { useSignals } from "@preact/signals-react/runtime";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { userId,  useNewGetUser} from '../apiUtils.js'
//import { SelectUser,  UserCols, ChooseBatchRow, 
import { SelectUser, UserCols,
   ChooseBatchRow, PPSelectUserRow,
   //BatchesContainer, 
  } from '../components/displayComponents.js'


export function Home() {
  useSignals();
  let user = useNewGetUser();
//  console.log(`At Home, user from useNewGetUser:`,{user});
  //if 
  if (! userId.value) {
    return (<PPSelectUserRow />);
  }
  if (userId.value) {
    return (
    <>
    <PPSelectUserRow />
    <div className="flex-start-start">
      { /*
      <UserCols id={userId.value} />
    */ }
    </div>
     <ChooseBatchRow id= {userId.value} />
     { /*
     <BatchesContainer id= {userId.value} />
    */ }
      </>
    )
  }
  return ( <div>...Select a User... </div>) ;
}


