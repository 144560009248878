import {site_title} from '../apiUtils.js';
import {styled} from 'styled-components';
import classNames from 'classnames';
import _ from 'lodash';
import {typeOf, isEmpty, 
} from 'pk-ts-common-lib';

const tstStr = `border: solid yellow 1px; color:blue; padding: 2em; margin:2em`;

//export const TstS = styled.div`border: solid green 1px; color:blue; padding: 2em; margin:2em`;
//export const TstS = styled.div `border: solid yellow 1px; font-size: xx-large; color:blue; padding: 2em; margin:2em`;
//@ts-ignore
/*
export function TstS() {
//@ts-ignore
  return ({styled.div([tstStr])});

 } 
 */
//@ts-ignore
export const TstS = styled.div([tstStr]);

let bl1 = "Simple, effective, and illuminating";

let bl2 = "New standard for food data and transparency";



export function About() {
   return (
    <div className="bc-800 m5 p5">
   <div className="bg-blue-100 p-4 m-4 bordder-green-800 text-2xl text-red-800">
	   <img src='./Lemon-Link-1.jpg' />
	 </div>
   <div className="bl1">{bl1}</div>
   <div className="bl2">{bl2}</div>
   </div>
 );
}
