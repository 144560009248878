import { useState,useContext,createContext } from 'react'
import PPNavBar from "./components/PPNavBar.js"
import { useSignals } from "@preact/signals-react/runtime";
import Container from 'react-bootstrap/Container';
import { NavVals, BuildRoutes, } from 'pk-ts-fe-lib/react-utils';
import { Batch } from './pages/batch.tsx';
import { About } from './pages/about.tsx';
import { Home } from './pages/home.tsx';
import { Tstapi } from './pages/tstapi.tsx';
import { styled } from 'styled-components';
import { Play } from './pages/play.tsx';
export const userContext = createContext(null);
export const batchContext = createContext(null);
export const saleContext = createContext(null);
import {
  BrowserRouter, 
  Route, Link, NavLink, Routes, useRouteError, createBrowserRouter, RouterProvider,
} from 'react-router-dom';
import { sysRefs, useGetRefs, userId, useNewGetUser, 
  site_title,
} from './apiUtils.js';
import { SelectUser, PPSelectUserRow } from './components/displayComponents.js';
import _ from 'lodash';
//import './App.css'

let rNavVals: NavVals = {
  brand: { path: "/", component: About, label: (<div className='pp-nav-brand'>{site_title}</div>) },
  about: { path: "/about", component: About, label: "About", },
  home: { path: "/home", component: Home, label: "Demo", },
  batch: { path: "/batch", component: Batch, label: "Batch", },
  tstapi: { path: "/tstapi", component: Tstapi, label: "TstApi", },
  //play: {path:"/play", component: Play, label: "Play",},
};

function App() {
  useSignals();
  const [uid, setUid] = useState(null);
//  const [count, setCount] = useState(0)
  let { refs, isError, isLoading } = useGetRefs();
  if (isLoading) {
    return (<h1>Loading User Container...</h1>);
  }
  Object.assign(sysRefs, refs);

  window.top.document.title=site_title;

        //<PPSelectUserRow setUid={setUid} />



  return (
    <>
      <Container fluid>
        {PPNavBar(rNavVals)}
        <BrowserRouter>
          <Routes>
            {
              Object.entries(rNavVals).map(([key, val]) => (
                <Route key={key} path={val.path} Component={val.component} />
              ))
            }
          </Routes>
        </BrowserRouter>
      </Container>
    </>
  )
}

export default App
