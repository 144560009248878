import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {NavVals, isActive} from 'pk-ts-fe-lib/react-utils';
import {GenObj, } from 'pk-ts-fe-lib';

/**
 * If 'brand' is a key of origNavVals, it is removed from the links and used in the NavBar.Brand Component 
 * @param origNavVals 
 * @returns <NavBar.Brand> or Fragment
 */
function MainNavbar(origNavVals:NavVals) {
  let navVals = {...origNavVals};
  let brandVal:null|GenObj;
  if ('brand' in navVals) {
    brandVal = navVals.brand;
    delete navVals.brand;
  }

  return (
    <Navbar expand="md" variant="tabs" className="bg-body-tertiary pp-main-nav">
      <Container>
        {brandVal ? 
        (<Navbar.Brand className="brand-class" href={brandVal.path}>{brandVal.label}</Navbar.Brand>) : (<></>)} 
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" >
          <Nav className="me-auto" >
                {
      Object.entries(navVals).map(([key, val]) => (
                <Nav.Link  key={key} eventKey={val.path}  className={isActive(val.path) } href={val.path} >{val.label}</Nav.Link>
      ))
    }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MainNavbar;
