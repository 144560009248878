/**
 * Testing the API from the FE 
 */
import Select from 'react-select';
import classNames from 'classnames';
import { useForm, SubmitHandler, } from 'react-hook-form';
import { useSignals } from "@preact/signals-react/runtime";
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { Component, useState, useEffect, } from 'react';
import _ from 'lodash';
import { isEmpty, firstToUpper, typeOf, typeOfEach, GenObj, insertBetween, JSON5Stringify, pkToDate, } from 'pk-ts-common-lib';
import apiLib, {
   inCreateBatch, paxios,
   //useAxiosFetchBatches, 
   signalBatch, sysRefs, batchId, useGetUsers, useGetUser, userId, useNewGetUser,
} from '../apiUtils.js';
import {
   SelectUser, UserCols, ChooseBatchRow, Waiting, mkSelectBatchOptions,
   VerticalPair, ValLabel,
   PPSelectUserRow,
   //BatchesContainer,

} from '../components/displayComponents.js';
import { normOnSelFncs, } from 'pk-ts-fe-lib/react-hook-form';
import { SelectFarm, RefSelect, RefSelectPairT } from '../components/BatchForm.js';
import {
   BatchesTopRow, BatchesSummary, RowSS, ColCC, RowCG,
} from '../components/batchComponents.js';

/**
 * A generic customizable container for content
 */
//export function ABox({xclasses:any = "", ...props}) {


export function ABox(props) {
   let defaultClassName = "bc-800 m3 p3";
   let className = classNames(defaultClassName, props.classes, props.className);
   return (
      <div {...props} className={className}>
      </div>
   );
}

/**
 * for where conditions - in a form - an empty value should not be 'whereed'
 */
function filterEmptyProps(obj: GenObj): GenObj {
   let ret: GenObj = {};
   for (let key in obj) {
      let val = obj[key];
      if (val) ret[key] = val;
   }
   return ret;
}

/**
 * The filter batches form - assumes a user
 */
export function FilterBatchesForm({ uId, batches = null, setBatches = null, initVals = {}, ...props }) {
   let baseCond = { where: { "user.id": uId } };
   let { register, handleSubmit, formState: { errors } } = useForm();
   async function onSubmitHandler(formData: GenObj) {
      let filtered = filterEmptyProps(formData);
      let whered = { where: filtered };
      //console.log(`Filtering batches with data:`, { formData, filtered, whered });
      let res = await paxios.post('/fetchbatches', whered);
      let data = res.data;
      let lbatches = data.batches;
      setBatches(lbatches);

      //console.log(`Return from useFetchBatches: `, { res });
   }
   //<SelectFarm register={register} all="All"/>
   return (
      <>
         <RowSS className="p5 bc-a40 fs3 tac" >
            Filter Batches
         </RowSS>
         <RowSS className="p5 bc-040" >
            <form className="bc-aaa p3 m3" onSubmit={handleSubmit(onSubmitHandler)}>

               <input type="hidden" name="user.id" value={uId} {...register("user.id")} />
               <table>
                  <tbody>
                     {RefSelectPairT({ required: false, register, cat: "product", all: true, })}
                     {RefSelectPairT({ required: false, register, cat: "quality", all: true, })}
                     { /* @ts-ignore */}
                     { /*
                     // Seems to return a div? Even though looks like a tr in insepct elements
                     {SelectFarm({ register, all: true })}
   */ }
                  </tbody>
               </table>

               <RowCG style={{ justifyContent: "space-between" }}>
                  <Button type="submit" className="tac">Filter Batches</Button>
               </RowCG>

            </form>
         </RowSS>
      </>
   );

}
// <Button type="reset" onClick={onCancelHandler} className="tac">Cancel</Button>
export function FilterBatches({ uId, setBatches, ...props }) {
   return (<FilterBatchesForm uId={uId} setBatches={setBatches} />);
}

/**
 * For react conditional rendering - should return false for anything not rederable
 */
export function somethingToShow(arg) {
   //console.log(`in somethingToShow - a kinda react filter? arg:`, { arg });
   if (isEmpty(arg)) {
      return false;
   }
   return true;

}


/**
 * Only returned if a user already selected.
 */
export function BatchBox({ uId, batches, setBatches, ...props }) {
   //console.log(`In BatchBox, uId:`, { uId });
   return (
      <>
         <FilterBatches uId={uId} batches={batches} setBatches={setBatches} />
         {somethingToShow(batches) ? (<BatchesSummary batches={batches} />) : (<h2>No matching batches</h2>)}

      </>
   );
   //   return (<ABox className="fs-8 c-f00">Who, me? MyID: {uId}</ABox>)
}


export function Tstapi() {
   useSignals();
   let [batches, setBatches] = useState(null);
   let user = useNewGetUser();
   if (!user || !userId.value) {
      return (<PPSelectUserRow />);
   }
   return (
      <>
         <PPSelectUserRow />
         <BatchBox uId={user.id} user={user} setBatches={setBatches} batches={batches} />
      </>
   );
}



/**
 * Selecct a batch from the prop. onSelFncs is optional
 * fnc or array of fncs to be called in onSelect
 */

export function SelectBatch2({ selectedOption = null, batches, ...props }) {
   useSignals();
   if (isEmpty(batches)) {
      return (<Waiting />);
   };

   let onSelFncs = normOnSelFncs(props.onSelFncs);

   let options = mkSelectBatchOptions(batches);

   function onBatchSelect(arg) {
      let val = arg.value;
      for (let onSelFnc of onSelFncs) {
         //@ts-ignore
         onSelFnc(val, arg, batches);
      }
      selectedOption = val;
      //console.log(`in onBatchSelect; selected:`, { batches });
   }

   return (
      <>
         <VerticalPair className="w10 tac" style={{ width: "10em" }}>
            <ValLabel>Select a Batch</ValLabel>
            <Select className="select-user mh5"
               defaultValue={selectedOption}
               onChange={onBatchSelect}
               options={options}
            />
         </VerticalPair>
      </>
   );


}







