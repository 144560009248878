/**
 * Batch Form based on Llama 3
 */

import { useSignals } from "@preact/signals-react/runtime";
import { format, } from "date-fns/format";
import Select from "react-select";
import apiLib from '../apiUtils.js';
import React, {useState} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useForm, SubmitHandler, } from 'react-hook-form';
import { userId, useFetchFarms,
  //usePostBatch, 
  axiosPostBatch, sysRefs, batchId, signalBatch, useGetInstances } from '../apiUtils.js';
import { ValLabel,DataVal, VerticalPair, formatters } from './displayComponents.js'
import { RowCC, RowSS, RowGG, RowGC, RowCG, ColSS, ColSC, ColSG } from './batchComponents.js'
import * as Yup from 'yup';
import { Container, Row, Col, Button, Form as BootstrapForm } from 'react-bootstrap';
import { isEmpty, dtFmt, GenObj, typeOf, firstToUpper } from 'pk-ts-common-lib';
import { normOnSelFncs,} from 'pk-ts-fe-lib/react-hook-form';

/**
 * Use both for creating batch, & filtering batch
 * When filtering, offer the "All" option, but filter only to include farms that
 * the user has batches from.
 * When create, need to find the user's farm to make default.
 * 
 */
export function SelectFarm({uId, register, selectedOption, onSelFncs, all, className="ctl-pair", ...props}) {
  useSignals();
  let farms = useFetchFarms();
  if (!farms) {
    return (<div>...Loading</div>)
  }
  //@ts-ignore
  let options =  farms.map((val,key) => ( <option key={key} value={val.id}>{val.name}</option>));
  if (all) {
    options.unshift((<option key="all" value=''>All</option>));
  }
  function onSelect(e) {
    let target = e.target;
    let val = e.target.value;
    for (let onSel of onSelFncs) {
      onSel(val,target, this);
    }
    //console.log(`in SelectFarm on change - arg:`, { val });
    selectedOption = val;
  }
        
  return (
    <tr className={className}>
      <td><label htmlFor="farm">Farm</label></td>
    <td><select
      {...register("farm")}
      defaultValue={selectedOption}
      onChange={onSelect}
    >
      {options}
    </select></td>
    </tr>
  );
}


         /*
        <option key='empty' value='' >{typeof empty === 'string' ? empty : "[Select one]"}</option>
      */ 
         /* @ts-ignore */

//Try form w. react-hook-form
// For react-hook-form selects
/**
 * @param empty - what to show for default/empty/unselected option, or null
 */
function mkRefSelOpts(cat: string, empty) {
  let catObj: any = sysRefs[cat];
  //console.log(`mkRefSelOpts: empty:`, {empty});
  //console.log(`in mkRefSelOpts:`, { cat, catObj });
  return (
    <>
      {empty ? (
        //@ts-ignore
        <option key='empty' value='' >{empty}</option>
      ) : (<></>)
      }
      {  Object.entries(catObj).map(([key, val]) => (
      //@ts-ignore
      <option key={key} value={key}>{val.name}</option>
      ))
      }
    </>
  );
}
/**
 * Make a "ReferenceSelect" form select
 * @paraam register: function from the hook-form useForm() hook.
 * @param (string) cat: The category name - product, quality, etc.
 * @param ?: optional - a function or array of functions to be called
 * on select
 */


export function RefSelect({ required, register, cat, onSelFncs, all, selectedOption, ...props }) {
  //console.log(`In RefSelect`, {onSelFncs, register, cat, all, selectedOption});
  if (!register) {
    register = () => {};
  }

  // React doesn't support components/spans as children of options - probably exists some workaround...
  /*
  function styleEmptyIfOnly(eVal, req=false) {
    if (!eVal) {
      return null;
    }
    let reqStyle = {background:"#aaf", color:"#f88",};
    let allStyle = {background:"#faa", color:"#88f",};
    console.log(`in styleEmpty; `,{eVal, req});
    return (
        (<span style={req ? reqStyle : allStyle}>
          {eVal}
          </span>)
    );
  }
  */

  function styleEmpty(eVal, req=false) {
    // Fix when figure out how to return content in select opts
    return eVal;
  }


  let empty:any;
  if (all) {
    empty = styleEmpty((typeof all === "string" ? all : "[All]"));
  } else if (required) {
    empty = styleEmpty((typeof required === "string" ? required : "[Select One]"), true);
  }
  onSelFncs = normOnSelFncs(onSelFncs);

  useSignals();
  function onSelect(e) {
    let target = e.target;
    let val = e.target.value;
    onSelFncs = normOnSelFncs(onSelFncs);
    for (let onSel of onSelFncs) {
      onSel(val,target, this);
    }

  //  console.log(`in SelectProduct on change - arg:`, { val });
    selectedOption = val;
  }


  //console.log(`in RefSelect - empty:`,{empty});
  return (
    <select
      {...register(cat)}
      defaultValue={selectedOption}
      onChange={onSelect}
      required={!!required}
    >
      {mkRefSelOpts(cat, empty)}
    </select>
  )

}

/**
 * Create a ReferenceSelect label/Select pair
 * @param register - function from form
 * @param cat: string - the category
 * @param label - string (optional): The label, else defaut to cat name
 */


export function RefSelectPairT({ required, register, cat, selectedOption=null, all=null, onSelFncs=null, className="ctl-pair", ...props }) {
  useSignals();
  let label = props.label || firstToUpper(cat);
  return (
    <tr className={className}>
      <td> <label htmlFor={cat}>{label}</label></td>
      <td><RefSelect required={required} register={register} cat={cat} all={all} onSelFncs={onSelFncs} selectedOption={selectedOption}/></td>
    </tr>
  )
}

// Try form with table layout
export const BatchFormHF = ({setShowCreate, ...props}) => {
  useSignals();
  const { register, handleSubmit, watch, setValue, formState: { errors }, } = useForm();
  let [units, setUnits] = useState('');
  //let unitVal = '??';

  async function submitHandler(data: GenObj) {
    //console.log(`BatchFormHF Submit Handler Data Before Submit:`, { data });
    let resData = await axiosPostBatch(data);
    batchId.value = resData.id;
    userId.value = `${userId.value} `;
    //console.log(`BatchFormHF Submit Handler resData AFTER Submit:`, { resData, newBatchId: batchId.value });
    setShowCreate(false);
    return resData;
  }

  let prodOnSel = (val) => {
    //console.log(`In product onSelect to set units - val: ${val}`);
    let prodInfo = sysRefs.product[val];
    let prodUnit = prodInfo.unit;
    //console.log(`Prod Info:`,{prodInfo});
    let unitName = sysRefs.units[prodUnit].name;
    //console.log(`Unit Name: [${unitName}]`);
    setUnits(unitName);
  };

  async function onCancelHandler() {
    setShowCreate(false);
  };
  return (
    <ColSS className="m2  p2 maxw40 bc-eee bg-eee" style={{ position:"absolute",}}>
      <div className="fs-l  c-000 bg-aaf fw-6 tac p1 fullw">Enter batch data</div>

      <form className="batch-form xfc-g-g" onSubmit={handleSubmit(submitHandler)}>
        <input type="hidden" name="userId" value={userId.value} {...register("userId")} />
        <table className="batch-table">
          <tbody>

        {RefSelectPairT({ register, cat: "product", className: "ctl-pair", required:"Select Product", onSelFncs:prodOnSel  })}

        {RefSelectPairT({ register, cat: "quality",  className: "ctl-pair", required:"Select Quality" })}

{ /* @ts-ignore */ }
{ /*
        <SelectFarm uid={userId.value} register={register}/>
  */ }

        <tr className="ctl-pair">
          <td className="form-td"><label htmlFor="quantity"># Packages</label></td>
          <td className="form-td"><input type="number" {...register('quantity')} defaultValue="1" min="1"
          step=".1"/>
          </td>

        </tr>

        <tr className="ctl-pair" >
          <td className="form-td"><label htmlFor="packageSize">Package Size</label></td>
          <td className="form-td dbl-inp"> <input type="number" {...register('packageSize')} defaultValue="0" min="0"
          step=".1"/>
          <div className="unit-lbl">{units}</div>
          </td>
        </tr>

        <tr className="ctl-pair" >
          <td className="form-td"><label htmlFor="harvestDate">HarvestDate</label></td>
          <td className="form-td"><input type="date" {...register('harvestDate')} defaultValue={format(new Date(), "yyyy-MM-dd")} max={dtFmt('html')} />
          </td>
        </tr>
        </tbody>
        </table>
        <RowCG style={{justifyContent:"space-between"}}>
        <Button type="submit" className="tac">Create New Batch</Button>
        <Button type="reset" onClick={onCancelHandler} className="tac">Cancel</Button>
        </RowCG>
      </form>
    </ColSS>
  );
}

//<input type="submit" />

/*
export interface BatchFormValuesI {
  name: string;
  product: string;
  quantity: number;
  packageSize: number;
  packageUnits: string;
  recordedBy: string;
  batchId: string;
  source: string;
  quality: string;
  bestBy: Date;
  harvestDate: Date;
}

const validationSchema = Yup.object().shape({
  //name: Yup.string().required('Name is required'),
  name: Yup.string(),
  //product: Yup.string().required('Product is required'),
  product: Yup.string(),
  //quantity: Yup.number().required('Quantity is required'),
  quantity: Yup.number(),
  //packageSize: Yup.number().required('Package size is required'),
  packageSize: Yup.number(),
  //packageUnits: Yup.string().required('Package units are required'),
  packageUnits: Yup.string(),
  //recordedBy: Yup.string().required('Recorded by is required'),
  recordedBy: Yup.string(),
  //batchId: Yup.string().required('Batch ID is required'),
  batchId: Yup.string(),
  //source: Yup.string().required('Source is required'),
  source: Yup.string(),
  //quality: Yup.string().required('Quality is required'),
  quality: Yup.string(),
  bestBy: Yup.date(),
  //  bestBy: Yup.date().required('Best by date is required'),
  //harvestDate: Yup.date().required('Harvest date is required'),
  harvestDate: Yup.date(),
});


export const BatchForm: React.FC = () => {
  useSignals();
  return (
    <Formik
      initialValues={{
        name: '',
        product: '',
        quantity: 0,
        packageSize: 0,
        packageUnits: '',
        recordedBy: '',
        batchId: '',
        source: '',
        quality: '',
        bestBy: '',
        harvestDate: '2024-05-12',
        //bestBy: (new Date()).toISOString(),
        //harvestDate: (new Date()).toISOString(),
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        // Call your backend API here
        //console.log(values);
        setSubmitting(false);
      }}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <Row>
            <Col>
              <label>Name</label>
              <Field type="text" name="name" className="form-control" />
              <ErrorMessage name="name" component="div" className="text-danger" />
            </Col>
            <Col>
              <label>Product</label>
              <Field type="text" name="product" className="form-control" />
              <ErrorMessage name="product" component="div" className="text-danger" />
            </Col>
            <Col>
              <label>Quantity</label>
              <Field type="number" name="quantity" className="form-control" />
              <ErrorMessage name="quantity" component="div" className="text-danger" />
            </Col>
            <Col>
              <label>Package Size</label>
              <Field type="number" name="packageSize" className="form-control" />
              <ErrorMessage name="packageSize" component="div" className="text-danger" />
            </Col>
            <Col>
              <label>Package Units</label>
              <Field type="text" name="packageUnits" className="form-control" />
              <ErrorMessage name="packageUnits" component="div" className="text-danger" />
            </Col>
            <Col>
              <label>Recorded By</label>
              <Field type="text" name="recordedBy" className="form-control" />
              <ErrorMessage name="recordedBy" component="div" className="text-danger" />
            </Col>
            <Col>
              <label>Batch ID</label>
              <Field type="text" name="batchId" className="form-control" />
              <ErrorMessage name="batchId" component="div" className="text-danger" />
            </Col>
            <Col>
              <label>Source</label>
              <Field type="text" name="source" className="form-control" />
              <ErrorMessage name="source" component="div" className="text-danger" />
            </Col>
            <Col>
              <label>Quality</label>
              <Field type="text" name="quality" className="form-control" />
              <ErrorMessage name="quality" component="div" className="text-danger" />
            </Col>
            <Col>
              <label>Best By</label>
              <Field type="date" name="bestBy" className="form-control" />
              <ErrorMessage name="bestBy" component="div" className="text-danger" />
            </Col>
            <Col>
              <label>Harvest Date</label>
              <Field type="date" name="harvestDate" className="form-control" />
              <ErrorMessage name="harvestDate" component="div" className="text-danger" />
            </Col>
          </Row>
          <button type="submit" disabled={isSubmitting} className="btn btn-primary">
            Submit
          </button>
        </Form>
      )}
    </Formik>
  );
};
*/

//For react-select
/*
export function mkRefSelOptsRS(cat: string) {
  let catObj: any = sysRefs[cat];
//  console.log(`in mkRefSelOptsRS:`, { cat, catObj });
  let options = [];
  Object.entries(catObj).map(([key, val]) => (
    //@ts-ignore
    options.push({ value: key, label: val.name })
  ))
  return options;
}
*/

/*
export function RefSelectPair({ required, register, cat, selectedOption=null, all=null, onSelFncs=null, className="ctl-pair", ...props }) {
  useSignals();
  let label = props.label || firstToUpper(cat);
  return (
    <div className={className}>
      <label htmlFor={cat}>{label}</label>
      <RefSelect required={required} register={register} cat={cat} all={all} onSelFncs={onSelFncs} selectedOption={selectedOption}/>
    </div>
  )
}
*/


//export default FormComponent;